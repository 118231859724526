import React from "react";
import { AffinityIconProps } from "../../shared/typings";

/** Affinity Insight product icon */
export const AffinityInsightIcon = ({
  size = "40px",
  inactive = false,
  bgColor = false,
  fgColor = false
}: AffinityIconProps) => {
  return (
    <svg
      style={inactive ? { opacity: 0.5 } : {}}
      width={size}
      height={size}
      viewBox="0 0 128 128"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Affinity-Insight-Icon-512x512"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Insight-Icon">
          {!inactive && (
            <path
              d="M35.9011765,-4.61852778e-14 L92.0988235,0.578705048 C111.926496,0.578705048 128,18.7129833 128,41.0827492 L128,87.4959559 C128,109.865722 111.926496,128 92.0988235,128 L35.9011765,127.421295 C16.0735042,127.421295 0,109.287017 0,86.9172508 L0,40.5040441 C0,18.1342783 16.0735042,-4.61852778e-14 35.9011765,-4.61852778e-14 Z"
              id="Rectangle"
              fill={bgColor || "#635D93"}
            />
          )}
          <path
            d="M35.9011765,0 L92.0988235,0.578705048 C111.926496,0.578705048 128,18.7129833 128,41.0827492 L128,79.9959559 C128,102.365722 111.926496,120.5 92.0988235,120.5 L35.9011765,119.921295 C16.0735042,119.921295 0,101.787017 0,79.4172508 L0,40.5040441 C0,18.1342783 16.0735042,0 35.9011765,0 Z"
            id="Rectangle"
            fill={bgColor || "#9790C6"}
          />
          <path
            d="M64,65 C69.1086339,65 73.25,60.8586339 73.25,55.75 C73.25,50.6413661 69.1086339,46.5 64,46.5 C63.8554688,46.5 63.7282813,46.5346875 63.5880859,46.5419141 C64.4980892,49.0479869 63.8748393,51.8542938 61.9895665,53.7395665 C60.1042938,55.6248393 57.2979869,56.2480892 54.7919141,55.3380859 C54.7919141,55.4826172 54.75,55.6098047 54.75,55.75 C54.75,60.8586339 58.8913661,65 64,65 L64,65 Z M104.99862,58.274349 C97.1842123,43.0503841 81.7139052,32.75 64,32.75 C46.2860948,32.75 30.8114648,43.0575781 23.0013805,58.2757878 C22.3328732,59.5961762 22.3328732,61.1552626 23.0013805,62.475651 C30.8157877,77.6996159 46.2860948,88 64,88 C81.7139052,88 97.1885352,77.6924219 104.99862,62.4742122 C105.667127,61.1538238 105.667127,59.5947374 104.99862,58.274349 Z M64.125,37.5 C74.2732323,37.5 82.5,45.7267677 82.5,55.875 C82.5,66.0232323 74.2732323,74.25 64.125,74.25 C53.9767677,74.25 45.75,66.0232323 45.75,55.875 C45.7610764,45.7313593 53.9813593,37.5110764 64.125,37.5 Z M64.125,83.25 C48.6605469,83.25 34.5299316,74.4560104 27.25,60.3004268 C31.3443209,52.2966361 37.8919984,45.7992099 45.9438965,41.75 C42.9377197,45.6256092 41.078125,50.4278073 41.078125,55.7105122 C41.078125,68.3852115 51.3965624,78.6600854 64.125,78.6600854 C76.8534376,78.6600854 87.171875,68.3852115 87.171875,55.7105122 C87.171875,50.4278073 85.3122803,45.6256092 82.3061035,41.75 C90.3580016,45.7992099 96.9056791,52.2966361 101,60.3004268 C93.7215088,74.4560104 79.5894531,83.25 64.125,83.25 Z"
            id="c"
            fill={fgColor || "#271E3E"}
          />
        </g>
      </g>
    </svg>
  );
};
