import React, { Suspense, PropsWithChildren } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";

import { useAuth } from "@akj-dev/affinity-platform";

import { ForgotPassword } from "../auth/ForgotPassword";
import { ProductLoadingScreen } from "../components/ProductLoadingScreen/ProductLoadingScreen";
import { useGtm } from "../hooks/useGtm";
import { AffinityNavBar } from "../nav/AffinityNavBar";
import { PlatformMenu } from "../nav/PlatformMenu/PlatformMenu";
import { ForbiddenScreen } from "../screens/ForbiddenScreen";

type PrivateRouteProps = {
  path: string;
  permission?: string;
};

const PrivateRoute = ({
  path,
  permission,
  children
}: PropsWithChildren<PrivateRouteProps>) => (
  <Route
    path={path}
    render={() => (permission === "1" ? children : <ForbiddenScreen />)}
  />
);

const routes = [
  { path: "/customers", permission: "can_use_affinity_customers" },
  { path: "/sales", permission: "can_use_affinity_sales" },
  { path: "/orders", permission: "can_use_affinity_orders" },
  { path: "/billing", permission: "can_use_affinity_billing" },
  { path: "/insight", permission: "can_use_affinity_insight" },
  { path: "/hub", permission: "can_use_affinity_hub" },
  { path: "/search", permission: "can_use_platform_global_search" }
];

interface IStandaloneAppLayoutProps {
  root: string;
}

export const StandaloneAppLayout = ({
  children,
  root
}: PropsWithChildren<IStandaloneAppLayoutProps>) => {
  const { accountSettings } = useAuth();
  const { pathname } = useLocation();

  useGtm();

  return (
    <Switch>
      <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
      {pathname === "/" && root !== "/" && <Redirect from="/" to={root} />}
      <Route path="/forgot">
        <ForgotPassword />
      </Route>
      <Route path="/">
        <AffinityNavBar />
        <PlatformMenu />
        <Suspense fallback={<ProductLoadingScreen />}>
          <Switch>
            <Route path="/" exact>
              {children}
            </Route>

            {routes.map(route => (
              <PrivateRoute
                key={route.path}
                path={route.path}
                permission={accountSettings[route.permission]}
              >
                {children}
              </PrivateRoute>
            ))}

            <PrivateRoute
              permission={
                accountSettings.can_use_affinity_support === "1"
                  ? accountSettings.can_use_affinity_support
                  : accountSettings.can_use_affinity_support_new
              }
              path="/support"
            >
              {children}
            </PrivateRoute>
          </Switch>
        </Suspense>
      </Route>
    </Switch>
  );
};
