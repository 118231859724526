import Cookies from "js-cookie";

import { APP_BRANDS, APP_SUBDOMAINS } from "./appSubdomains";

export enum AuthCookies {
  REFRESH_TOKEN = "ap_refreshToken",
  USER_ROLE = "ap_userRole",
  API_BASE_URL = "ap_apiBaseUrl",
  SESSION_TOKEN = "ap_sessionToken",
  UAT_BANNER_AUTH_API = "ap_uatBannerAuthApi"
}

/**
 * Check if user is using standard version of application or partner / uat one
 * @returns true if user uses standard version of app (url start with app name)
 */
const isBaseAppUrl = () =>
  ["localhost", "affinity.io"].includes(window.location.hostname) ||
  APP_SUBDOMAINS.some(appSubdomain =>
    window.location.hostname.startsWith(appSubdomain)
  );

/**
 * Calculates prefix of base domain {appName}.affinity.io
 * @returns prefix (e.g. vodafone.uat.sales.affinity.io => vodafone.uat)
 */
const getPrefixOfBaseAppUrl = () => {
  const hostname = window.location.hostname;
  let startOfAppUrl = 0;

  APP_SUBDOMAINS.forEach(appSubdomain => {
    const indexOfApp = hostname.indexOf(appSubdomain);

    if (indexOfApp !== -1) {
      startOfAppUrl = indexOfApp;
    }
  });

  return hostname.substring(0, startOfAppUrl > 0 ? startOfAppUrl - 1 : 0);
};

const getBrandingFromAppUrl = () =>
  window.location.hostname.split(".").find(part => APP_BRANDS.includes(part));

/**
 * We need to construct different cookie names for case when one user uses uat and live version in one browser
 * @param cookie name of the cookie to be transformed to cookie for specific env
 * @returns cookie name with suffix of the current env / client app
 */
export const constructCookieName = (cookie: string) => {
  if (window.location.hostname.includes("affinityreview.dev.aurora.io")) {
    return `${cookie}_review`;
  }

  const isUat = window.location.hostname.includes("uat");
  const brand = getBrandingFromAppUrl();

  return `${cookie}${brand ? `_${brand}` : ""}${isUat ? "_uat" : ""}`;
};
/**
 * We need to get base domain for sharing cookies between subdomains
 * @returns base domain usually affinity.io
 */
export const getDomain = () => {
  const hostname = window.location.hostname;

  if (hostname === "affinity.io") {
    return hostname;
  }

  const baseAppPrefix = getPrefixOfBaseAppUrl();

  const baseHostname =
    isBaseAppUrl() || !baseAppPrefix
      ? hostname
      : hostname.replace(`${baseAppPrefix}.`, "");

  return baseHostname.substring(
    baseHostname.indexOf(".") + 1,
    baseHostname.length
  );
};

export const setCookieValue = (key: AuthCookies, value: string) => {
  Cookies.set(constructCookieName(key), value, {
    expires: 365,
    domain: getDomain()
  });
};

export const getCookieValue = (key: AuthCookies) =>
  Cookies.get(constructCookieName(key));

export const clearCookies = () =>
  Object.values(AuthCookies).map(cookie =>
    Cookies.remove(constructCookieName(cookie))
  );
