import React, { ReactNode } from "react";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

interface MainProps {
  children: ReactNode;
  title: string;
}

function Main({ children, title }: MainProps) {
  return (
    <Box display="flex" flexDirection="column" paddingTop={1} paddingBottom={1}>
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        marginBottom={2}
      >
        <Box>
          <Typography variant="h2" gutterBottom>
            {title}
          </Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        flexGrow={1}
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        {children}
      </Box>
    </Box>
  );
}

export default Main;
