import React from "react";
import { AffinityIconProps } from "../../shared/typings";

/** Affinity Sales product icon */
export const AffinitySalesIcon = ({
  size = "40px",
  inactive = false,
  bgColor = false,
  fgColor = false
}: AffinityIconProps) => {
  return (
    <svg
      style={inactive ? { opacity: 0.5 } : {}}
      width={size}
      height={size}
      viewBox="0 0 512 512"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        id="Brandmarks/Affinity/Icons/1.Sales-Icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Sales-Icon">
          {!inactive && (
            <path
              d="M143.530709,2.27373675e-13 L368.469291,1.0856929 C447.739112,1.0856929 512,73.7977322 512,163.492578 L512,349.593115 C512,439.287961 447.739112,512 368.469291,512 L143.530709,510.914307 C64.2608875,510.914307 0,438.202268 0,348.507422 L0,162.406885 C0,72.7120393 64.2608875,2.27373675e-13 143.530709,2.27373675e-13 Z"
              id="Rectangle"
              fill={bgColor || "#2386BE"}
            />
          )}
          <path
            d="M143.530709,2.27373675e-13 L368.469291,1.0856929 C447.739112,1.0856929 512,73.7977322 512,163.492578 L512,319.593115 C512,409.287961 447.739112,482 368.469291,482 L143.530709,480.914307 C64.2608875,480.914307 0,408.202268 0,318.507422 L0,162.406885 C0,72.7120393 64.2608875,2.27373675e-13 143.530709,2.27373675e-13 Z"
            id="Rectangle"
            fill={bgColor || "#56B9F1"}
          />
          <path
            d="M392.681689,329.597867 C395.125639,329.597867 397.105207,331.58723 397.105207,334.043273 L397.105207,342.934085 C397.105207,345.390129 395.125639,347.379491 392.681689,347.379491 L122.847038,347.379491 C117.959136,347.379491 114,343.400766 114,338.488679 L114,138.445406 C114,135.989363 115.979568,134 118.423519,134 L127.270557,134 C129.714508,134 131.694075,135.989363 131.694075,138.445406 L131.694075,329.597867 L392.681689,329.597867 Z M168.376019,244.596298 L214.889385,198.29722 C218.345259,194.851922 223.924336,194.863318 227.36941,198.324896 L274.164646,245.35167 L319.62721,199.664161 L294.90529,174.81992 C286.445418,166.318189 292.4338,151.781624 304.399224,151.781624 L370.458798,151.781624 C375.402318,151.781624 379.411132,155.810274 379.411132,160.778254 L379.411132,227.164684 C379.411132,235.299582 372.786653,240.689746 365.886245,240.689746 C362.585345,240.689746 359.223427,239.461724 356.486267,236.71102 L332.134774,212.239038 L280.418158,264.211544 C276.962284,267.684517 271.361068,267.684517 267.905194,264.211544 L221.07702,217.151125 L180.883584,257.165749 C179.158346,258.89898 176.354769,258.89898 174.630071,257.165749 L168.376019,250.880752 C166.650782,249.146979 166.650782,246.330072 168.376019,244.596298 Z M314.695029,169.563249 L361.717056,216.817937 L361.717056,169.563249 L314.695029,169.563249 Z"
            id="c"
            fill={fgColor || "#271E3E"}
            fillRule="nonzero"
          />
        </g>
      </g>
    </svg>
  );
};
