import React, { useState } from "react";
import { useAuth } from "@akj-dev/affinity-platform";
import { Button, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { StatusChip, LoaderButton } from "@akj-dev/design-system";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

interface LogoutDialogProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: "center"
    // padding: 24
  },
  button: {
    margin: theme.spacing(1)
  }
}));

export const LogoutDialog: React.FC<LogoutDialogProps> = ({
  open,
  onClose
}) => {
  const classes = useStyles();
  const { logout } = useAuth();
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogout = async () => {
    setLoading(true);
    const response = await logout();
    if (response === "success") {
      setLoading(false);
      history.push("/");
    } else {
      setError(response);
    }
    setLoading(false);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Are you sure you want to logout?</DialogTitle>
      <DialogContent className={classes.content}>
        <LoaderButton
          loading={loading}
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={handleLogout}
        >
          Yes, log me out
        </LoaderButton>
        <Button
          variant="text"
          color="secondary"
          onClick={onClose}
          className={classes.button}
        >
          Cancel
        </Button>
        {error && <StatusChip type="error" message={error} />}
      </DialogContent>
    </Dialog>
  );
};
