import React from "react";

interface AffinityLogoProps {
  color?: string;
  width?: number;
  className?: string;
}

export const AffinityLogotype: React.FC<AffinityLogoProps> = ({
  color = "#ffffff",
  width = 150,
  className
}) => {
  return (
    <svg
      width={width}
      height={width * 0.1}
      viewBox="0 0 149 15"
      version="1.1"
      className={className}
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-20.000000, -23.000000)" fill={color}>
          <g>
            <g transform="translate(20.000000, 23.000000)">
              <g>
                <path
                  d="M1.77011409,14.9481908 L0,14.9481908 L6.48918651,0.00863486842 L7.96736111,0.00863486842 L14.4565476,14.9481908 L12.6864335,14.9481908 L11.0419643,11.0217928 L3.41458333,11.0217928 L1.77011409,14.9481908 Z M3.98737599,9.63034539 L10.4543899,9.63034539 L7.22827381,1.78125 L3.98737599,9.63034539 Z"
                  id="Shape"
                />
                <polygon
                  id="Path"
                  points="26.4260665 14.9481908 24.9072421 14.9481908 24.9072421 0.00863486842 33.90563 0.00863486842 33.90563 1.40008224 26.4260665 1.40008224 26.4260665 6.48478618 33.380878 6.48478618 33.380878 7.87993421 26.4260665 7.87993421"
                />
                <polygon
                  id="Path"
                  points="46.5625 14.9481908 45.0436756 14.9481908 45.0436756 0.00863486842 54.0420635 0.00863486842 54.0420635 1.40008224 46.5625 1.40008224 46.5625 6.48478618 53.5173115 6.48478618 53.5173115 7.87993421 46.5625 7.87993421"
                />
                <polygon
                  id="Path"
                  points="66.7174107 14.9481908 65.2022817 14.9481908 65.2022817 0.00863486842 66.7174107 0.00863486842"
                />
                <polygon
                  id="Path"
                  points="89.983879 12.6685855 90.0282242 12.6685855 90.0282242 0.00863486842 91.5433532 0.00863486842 91.5433532 14.9481908 89.6475942 14.9481908 80.7526786 2.15871711 80.7120288 2.15871711 80.7120288 14.9481908 79.1932044 14.9481908 79.1932044 0.00863486842 81.0926587 0.00863486842"
                />
                <polygon
                  id="Path"
                  points="105.537971 14.9481908 104.019147 14.9481908 104.019147 0.00863486842 105.537971 0.00863486842"
                />
                <polygon
                  id="Path"
                  points="122.79566 14.9481908 121.276835 14.9481908 121.276835 1.40008224 116.284301 1.40008224 116.284301 0.00863486842 127.79189 0.00863486842 127.79189 1.40008224 122.79566 1.40008224"
                />
                <polygon
                  id="Path"
                  points="143.320114 14.9481908 141.804985 14.9481908 141.804985 8.51274671 136.221181 0.00123355263 138.116939 0.00123355263 142.551463 7.03248355 146.985987 0.00123355263 148.926091 0.00123355263 143.338591 8.51274671"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
